<template>
	<div class="app-container">
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.list.filters')}}</span>
				<span style="float: right">
					<el-button type="primary" size="mini" icon="el-icon-check" plain @click.native="applyFilters">
						{{$t('general.apply')}}</el-button>
				</span>
			</div>
			<el-row :gutter="12">
				<el-col :xs="24" :sm="12" :md="12" :lg="6">
					<div class="sub-title">{{$t('products.list.category')}}</div>
					<el-select class="fullWidth" v-model="selectedCategory">
						<el-option :key="0" :value="0" label="Alegeti o categorie"></el-option>
						<el-option :key="item.id" :value="item.id" :label="item.name" v-for="item in categories">
						</el-option>
					</el-select>
				</el-col>
			</el-row>
		</el-card>

		<div class="separator"></div>

		<el-card class="box-card">
			<el-table :data="prices" border style="width: 100%">
				<el-table-column :label="$t('reports.pricediff.model')">
					<template slot-scope="scope">
						<a target="_blank"
							:href="'https://marketplace.cel.ro/produse/lista?products_model=' + scope.row.products_model">{{ scope.row.products_model }}</a>
					</template>
				</el-table-column>
				<el-table-column prop="categories_name" :label="$t('reports.pricediff.categorie')">
				</el-table-column>
				<el-table-column :label="$t('reports.pricediff.celurl')">
					<template slot-scope="scope">
						<a target="_blank" :href="scope.row.celurl">{{ scope.row.celurl }}</a>
					</template>
				</el-table-column>
				<el-table-column prop="pretf" :label="$t('reports.pricediff.pretcel')">
				</el-table-column>
				<el-table-column prop="eprice_min" :label="$t('reports.pricediff.extprice')">
				</el-table-column>
			</el-table>
			<div class="center">
				<div class="separator"></div>
				<simple-pagination ref="sp" :currentpage="page" :pages="pages" @changePage="changePriceDiffPage"
					vspbutton="vspButton" vspbuttonselected="vspButton-selected" vspbuttonfast="vspButtonFast">
				</simple-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	const SimplePagination = () => import('@celdotro/vue-simplepagination');
	import {
		EventBus
	} from '@/utils/Bus.js';
	import {
		priceDiff
	} from '@/api/reports';

	import Vue from 'vue';

	import {
		currentDate,
		lastMonthDate,
		nFormatter
	} from '@/utils/index';

	export default {
		components: {
			Box,
			SimplePagination
		},
		data() {
			return {
				prices: [],
				categories: [],
				allPrices: [],
				selectedCategory: 0,
				pages: 0,
				page: 1
			};
		},
		methods: {
			getPriceDiff() {
				priceDiff({
					start: this.page
				}).then((result) => {
					this.prices = result.message.products;
					this.categories = result.message.categories;
					this.allPrices = result.message.products;
					this.pages = result.message.pages;
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'priceDiff',
							params: {
								start: this.page
							}
						}
					});
					console.log(e);
				});
			},
			applyFilters() {
				this.page = 1;
				this.$refs.sp.handlePageChange(this.page);
			},
			changePriceDiffPage(page) {
				const params = {
					start: page
				};
				if (this.selectedCategory !== 0) params.category = this.selectedCategory;

				priceDiff(params).then((result) => {
					this.prices = result.message.products;
					this.categories = result.message.categories;
					this.allPrices = result.message.products;
					this.pages = result.message.pages;
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'priceDiff',
							params
						}
					});
					console.log(e);
				});
			}
		},
		computed: {},
		watch: {},
		created() {
			this.getPriceDiff();
		},
		beforeDestroy() {
			// EventBus.$off('closeModal')
		}
	};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
	@import './src/styles/modules/reports.scss';

	.vspButton {
		height: 22px;
		padding: 2px 7px;
		font-size: 12px;
		display: inline-block;
		margin-bottom: 0;
		font-weight: 400;
		text-align: center;
		white-space: nowrap;
		vertical-align: middle;
		-ms-touch-action: manipulation;
		touch-action: manipulation;
		cursor: pointer;
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
		background-image: none;
		border: 1px solid transparent;
		border-radius: 4px;
		background-color: transparent;
	}

	button[class="vspButton"]:hover {
		background-color: #ffeee7;
		border: 1px solid #ff5f2d;
	}

	.vspButton-selected {
		background-color: #ff5f2b;
		color: white;
	}

	.vspButtonFast {
		background-color: #f4f8fb;
		color: #b5b9bc;
	}
</style>